<template>
    <div class="order-details-modal-container animation-open-modal" v-if="open">
        <header>
            <div class="header-container">
                <div class="header-container-left">
                    <div @click="backToOrderList">
                        <slot name="back-button" />
                    </div>
                    <h1>{{ `${orderData.orderNumber}/${orderData.operationNumber}` }}</h1>
                </div>
                <button @click="closeModal">
                    <CloseIcon v-if="windowWidth > 425 && windowWidth < 768" />
                    <CloseIconSmall v-else />
                </button>
            </div>

            <div class="graph-buttons-resources">
                <button
                    type="button"
                    :class="[
                        'btn-graph-resources',
                        showInformation ? 'btn-active' : 'btn-disabled'
                    ]"
                    @click="
                        () => {
                            showInformation = true;
                            showProgress = false;
                            showProductionProgress = false;
                            showLinks = false;
                        }
                    "
                >
                    {{ $t("Informations") }}
                </button>

                <button
                    type="button"
                    :class="['btn-graph-resources', showProgress ? 'btn-active' : 'btn-disabled']"
                    @click="
                        () => {
                            showProgress = true;
                            showInformation = false;
                            showProductionProgress = false;
                            showLinks = false;
                        }
                    "
                >
                    {{ $t("Progress") }}
                </button>

                <button
                    type="button"
                    :class="[
                        'btn-graph-resources',
                        showProductionProgress ? 'btn-active' : 'btn-disabled'
                    ]"
                    @click="
                        () => {
                            showProductionProgress = true;
                            showProgress = false;
                            showInformation = false;
                            showLinks = false;
                        }
                    "
                >
                    {{ $t("Production") }}
                </button>

                <button
                    type="button"
                    :class="['btn-graph-resources', showLinks ? 'btn-active' : 'btn-disabled']"
                    @click="
                        () => {
                            showLinks = true;
                            showProductionProgress = false;
                            showProgress = false;
                            showInformation = false;
                        }
                    "
                >
                    {{ $t("Links") }}
                </button>
            </div>
        </header>

        <main>
            <!-- TODO: componentes serão renderizados aqui -->
            <InfoTab v-if="showInformation" :data="componentsProps.infoTab" />
            <ProgressTab v-if="showProgress" :data="componentsProps.progressTab" />
            <TabLinks v-show="showLinks" :list.sync="linksList" />
            <TabNote
                v-if="showProductionProgress"
                :list="listOperationProgress"
                :isMultipleOrders="isMultipleOrders"
                :isControlByHour="isControlByHour"
                @deleteOperationProgress="handleAfterDeleteAppointment()"
            />
        </main>
    </div>
</template>

<script>
    import { mapActions, mapMutations } from "vuex";
    export default {
        components: {
            CloseIcon: () => import("@/assets/images/icons/close-icon-1.svg"),
            CloseIconSmall: () => import("@/assets/images/icons/close-icon.svg"),
            TabLinks: () => import("@/views/pages/operator/components/TabLinks/TabLinks.vue"),
            TabNote: () => import("@/views/pages/operator/components/TabNote/TabNote.vue"),
            InfoTab: () =>
                import("@/views/pages/operator/components/DetailsModal/components/InfoTab"),
            ProgressTab: () =>
                import("@/views/pages/operator/components/DetailsModal/components/ProgressTab")
        },
        props: {
            orderData: {
                type: Object,
                default: () => ({}),
                required: true
            },
            open: {
                type: Boolean,
                default: false
            },
            isMultipleOrders: {
                type: Boolean,
                default: false
            },
            isControlByHour: {
                type: Boolean,
                default: false
            }
        },
        destroyed() {},
        mounted() {
            if (this.open) this.addOpenAnimation();

            this.formatPropsInfoTab();
            this.formatPropsProgressTab();
            this.getLinks();
            this.getOperationProgress();

            $(function () {
                const slider = document.querySelector(".graph-buttons-resources");
                let mouseDown = false;
                let startX, scrollLeft;

                let startDragging = function (e) {
                    mouseDown = true;
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                };
                let stopDragging = function (event) {
                    mouseDown = false;
                };

                slider.addEventListener("mousemove", (e) => {
                    e.preventDefault();
                    if (!mouseDown) {
                        return;
                    }
                    const x = e.pageX - slider.offsetLeft;
                    const scroll = x - startX;
                    slider.scrollLeft = scrollLeft - scroll;
                });

                slider.addEventListener("mousedown", startDragging, false);
                slider.addEventListener("mouseup", stopDragging, false);
                slider.addEventListener("mouseleave", stopDragging, false);

                $(".b-form-tags-input").blur((event) => {
                    let input = vm.$refs.formTagOrders;
                    input.addTag(event.target.value);
                });
            });
        },
        data() {
            return {
                windowWidth: window.innerWidth,
                showInformation: true,
                showProgress: false,
                showProductionProgress: false,
                showLinks: false,
                hasDeletedAppointment: false,
                componentsProps: {
                    infoTab: {},
                    progressTab: {},
                    linksTab: {},
                    noteTabs: {}
                },
                prefix: localStorage.getItem("prefix") || this.$router.currentRoute.params.prefix,
                currentSite: this.$cookies.get("userInfo").currentSite,
                linksList: [],
                listOperationProgress: []
            };
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapActions("app", ["removeRequestLoading"]),
            handleAfterDeleteAppointment() {
                this.getOperationProgress();

                if (this.$router.currentRoute.params?.appointment_type == 1) {
                    this.hasDeletedAppointment = true;
                }
            },
            addOpenAnimation() {
                const modal = document.querySelector(".order-details-modal-container");
                modal.classList.add("animation-open-modal");

                setTimeout(
                    () =>
                        document
                            .querySelector(".order-details-modal-container")
                            .classList.remove("animation-open-modal"),
                    1000
                );
            },
            closeAnimation() {
                const modal = document.querySelector(".order-details-modal-container");
                modal.classList.add("animation-close-modal");
                modal.classList.add("closed-modal");

                setTimeout(() => this.$emit("close", false), 500);
            },
            closeModal() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                this.closeAnimation();
                this.$emit("closeListModal");

                this.$router.currentRoute.params?.appointment_type == 1 &&
                    this.checkHasReworkAppointments();
            },
            checkHasReworkAppointments() {
                const { params } = this.$router.currentRoute;
                const hasReworkAppointment = this.listOperationProgress.find(
                    (appointment) => appointment.is_rework == 1
                );

                if (this.hasDeletedAppointment && !hasReworkAppointment)
                    setTimeout(() => {
                        this.$router.push({
                            name: "operator-production-progress",
                            params: {
                                ...params,
                                appointment_type: 0
                            }
                        });
                        this.$router.go();
                    }, 500);
            },
            backToOrderList() {
                this.closeAnimation();
            },
            formatFloatStringForInt(value) {
                return parseFloat(value).toFixed();
            },
            formatPercentStringForIntNumber(value) {
                if (!value) return 0;
                const percent = 100;
                return Number((parseFloat(value) * percent).toFixed());
            },
            formatPercentStringForFloatNumber(value) {
                const percent = 100;
                return (Number(value) * percent).toFixed(2);
            },
            formatHour(value) {
                return parseFloat(value).toFixed(2).replace(".", ",");
            },
            formatHourAndMinute(value) {
                let number = Number(value);
                // Check sign of given number
                let sign = number >= 0 ? 1 : -1;

                // Set positive value of number of sign negative
                number = number * sign;

                // Separate the int from the decimal part
                const hour = Math.floor(number);
                let decpart = number - hour;

                const min = 1 / 60;
                // Round to nearest minute
                decpart = min * Math.round(decpart / min);

                let minute = Math.floor(decpart * 60) + "";

                // Add padding if need
                minute = minute.length < 2 ? "0" + minute : minute;

                // Add Sign in final result
                sign = sign == 1 ? "" : "-";

                // Concate hours and minutes
                return `${sign}${hour} h ${minute} m`;
            },
            formatPropsInfoTab() {
                const { orderData } = this;

                const observation = orderData.note;

                this.componentsProps.infoTab = {
                    Order: {
                        Quantity: {
                            value: this.formatFloatStringForInt(orderData.plannedQuantity),
                            truncate: false
                        },
                        Priority: {
                            value: this.formatFloatStringForInt(orderData.priority),
                            truncate: false
                        },
                        DueDate: {
                            value: orderData.dueDate,
                            truncate: false
                        }
                    },
                    Operation: {
                        OperationNo: {
                            value: orderData.operationNumber,
                            truncate: false
                        },
                        OperationName: {
                            value: orderData.operationName,
                            truncate: true
                        },
                        ProductName: {
                            value: orderData.product,
                            truncate: true
                        },
                        Resource: {
                            value: orderData.resources,
                            truncate: true
                        },
                        Workcenter: {
                            value: orderData.workcenter,
                            truncate: true
                        },
                        ForecastOperationSetup: {
                            value: orderData.setupStart,
                            truncate: false
                        },
                        ForecastOperationStart: {
                            value: orderData.startTime,
                            truncate: false
                        },
                        ForecastOperationEnd: {
                            value: orderData.endTime,
                            truncate: false
                        }
                    },
                    Attributes: orderData.attributes,
                    Observations: observation
                };
            },
            formatPropsProgressTab() {
                const { orderData, isControlByHour } = this;

                const setProgressInfoData = () =>
                    isControlByHour
                        ? {
                              forecastTime: {
                                  productiveTime: this.formatHourAndMinute(
                                      orderData.produtivePlannedTime
                                  ),
                                  total: this.formatHourAndMinute(orderData.totalPlannedTime)
                              },
                              myWork: {
                                  setup: this.formatHourAndMinute(orderData.progressTimeSetup),
                                  running: this.formatHourAndMinute(orderData.progressTimeRunning),
                                  stopped: this.formatHourAndMinute(orderData.progressTimeStopped)
                              },
                              totalTime: {
                                  netTime: this.formatHourAndMinute(orderData.totalLeadTime),
                                  completed: this.formatHourAndMinute(orderData.totalRunningTime)
                              }
                          }
                        : {
                              quantity: {
                                  planned: this.formatFloatStringForInt(orderData.plannedQuantity),
                                  produced: this.formatFloatStringForInt(
                                      orderData.producedQuantity
                                  ),
                                  rework: this.formatFloatStringForInt(orderData.reworkQuantity),
                                  scrap: this.formatFloatStringForInt(orderData.refuseQuantity)
                              }
                          };

                this.componentsProps.progressTab = {
                    progressData: {
                        theoretical_progress: this.formatPercentStringForIntNumber(
                            orderData.theoreticalProgress
                        ),
                        real_progress: this.formatPercentStringForIntNumber(orderData.realProgress)
                    },
                    progressInfoData: setProgressInfoData(),
                    statusData: orderData.lastInfo.lastStatus
                };
            },
            getLinks() {
                const configs = {
                    headers: {
                        ignoredRequest: true
                    }
                };

                this.$http
                    .get(
                        `/api/${this.prefix}/order/links?site_guid=${this.currentSite}&preactor_order_id=${this.orderData.operationNumber}`,
                        configs
                    )
                    .then((res) => {
                        this.linksList = res.data;
                    });
            },
            async getOperationProgress() {
                const GET_OPERATION_PROGRESS_URL = `/api/mysfc/operator/operation-progress?operationId=${this.orderData.id}&siteId=${this.currentSite}`;
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(GET_OPERATION_PROGRESS_URL);
                    this.listOperationProgress = response?.data?.data || [];
                    //manter essa linha pois quando apaga um apontamento esse método é chamado para atualizar a listagem, e loading só pode sumir quando popular a lista novamente
                    this.removeRequestLoading();
                } finally {
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .header-container {
        display: flex;
        align-items: center;
        padding: 16px 29px 15px;
        gap: 10px;
        background: #ffffff;
        border-bottom: 1px solid #cfc4be;
        justify-content: space-between;

        .header-container-left {
            display: flex;
            align-items: center;
        }

        h1 {
            margin: 0;
            font-weight: 600;
            font-size: 26px;
            line-height: 36px;
            color: #4c4541;
        }

        button {
            padding: 0;
            border: none;
            background: none;
        }
    }

    .graph-buttons-resources {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 58px;
        overflow-x: auto;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
            0px 1px 8px rgba(0, 0, 0, 0);

        &::-webkit-scrollbar {
            display: none !important;
        }

        .btn-graph-resources {
            font-size: 16px;
            border: none;
        }

        .btn-active {
            width: 100%;
            background-color: transparent;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #974900;
            box-shadow: inset 0px -4px 0px #974900;
            min-width: 192px;
        }

        .btn-disabled {
            width: 100%;
            background-color: transparent;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #998f8a;
            border-bottom: 1px solid #cfc4be;
            min-width: 192px;
        }
    }

    .order-details-modal-container {
        height: 100dvh;
        width: 100vw;
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9991;
        display: flex;
        flex-direction: column;

        main {
            overflow: auto;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #cfc4be;
                border-radius: 9px;
            }
        }

        &.animation-open-modal {
            animation: openModal 0.5s normal;

            @keyframes openModal {
                0% {
                    top: 100vh;
                }

                100% {
                    top: 0;
                }
            }
        }

        &.animation-close-modal {
            animation: closeModal 0.5s normal;

            @keyframes closeModal {
                0% {
                    top: 0;
                }

                100% {
                    top: 100vh;
                }
            }
        }

        &.closed-modal {
            top: 100vh;
        }

        .list-content {
            &::-webkit-scrollbar {
                width: 16px;
                /* width of the entire scrollbar */
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #cfc4be;
                /* color of the scroll thumb */
                border-radius: 20px;
                /* roundness of the scroll thumb */
                border: 4px solid #fff;
                height: 70px;
            }

            overflow: auto;
            padding: 0;
            margin: 0;
        }
    }

    .container-components {
        padding: 24px 29px;
    }

    @media (max-width: 768px) {
        .header-container {
            h1 {
                font-size: 36px;
                line-height: 50px;
            }
        }

        .graph-buttons-resources {
            .btn-active {
                font-size: 18px;
            }
        }
    }

    @media (max-width: 425px) {
        .header-container {
            padding: 15px 16px 14px;

            h1 {
                font-size: 18px;
                line-height: 26px !important;
            }
        }

        .graph-buttons-resources {
            .btn-active {
                font-size: 16px;
            }

            .btn-disabled {
                font-size: 16px;
            }
        }

        .container-components {
            padding: 24px 16px;
        }
    }
</style>
